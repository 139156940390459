import { fetchCurrentUser } from "~/composables/useAuth";
import { useAuthStore } from "~/stores/auth";

export default defineNuxtPlugin(async () => {
    const store = useAuthStore();
    store.setTempGuestId();

    if (store.user !== null) return;
    await fetchCurrentUser();

    return;
});