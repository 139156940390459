<script setup>
const error = useError();

</script>

<template>
    <div class="error-page">
        <div v-if="error.statusCode == 404" class="m-auto text-center">
            <a href="/">
                <img src="/images/footer-logo.png" alt="" style="max-width: 160px;">
            </a>
            <h1>Сраница потерялась, но поисковый отряд уже в пути</h1>
            <div class="text">А пока, <a href="/">посмотрите наши пиццы с доставкой за 30 минут</a></div>
        </div>
        <div v-else class="m-auto text-center">
            <a href="/">
                <img src="/images/footer-logo.png" alt="" style="max-width: 160px;">
            </a>
            <h1>Что-то сломалось</h1>
            {{ error.message }}
            <div class="text">Мы уже занимаемся решением проблемы</div>
        </div>
    </div>
</template>

<style lang="scss">
.error-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;

    h1 {
        margin-top: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;

        a {
            color: $primary;
            font-weight: 400;
            text-decoration: none;
            line-height: 1;

            &:hover {
                color: darken($primary, 10);
            }
        }
    }

}
</style>