import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    return;
    const router = useRouter();
    const { public: { sentry } } = useRuntimeConfig();

    if (!sentry.dsn) {
        return;
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://dominopizza.ru'],

        replaysSessionSampleRate: 0.2, // Change in prod
        replaysOnErrorSampleRate: 0.2, // Change in prod if necessary
    });
});