<script setup>
import { closeDialog } from 'vue3-promise-dialog';

const props = defineProps(['text']);
const { $bootstrap } = useNuxtApp();

var modal = null;

onMounted(() => {
    const router = useRouter();
    var modalEl = document.getElementById('error-modal');
    modal = new $bootstrap.Modal(modalEl, {});
    modalEl.addEventListener('hidden.bs.modal', function (event) {
        closeDialog(true);
    });
    modal.show();
});

onBeforeUnmount(() => {
    modal.hide();
});

const close = () => {
    modal.hide();
}

</script>
<template>
    <div class="modal fade error-modal" id="error-modal" tabindex="-1" aria-labelledby="error-modal-label"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <UiIconsInfoIcon />
                    <p v-html="text"></p>

                    <a href="#" class="button" @click="close">Ок</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.error-modal {

    .modal-content {
        background-color: #fff;
    }

    .modal-body {
        text-align: center;

        p {
            margin-top: 20px;
            font-size: 14px;
        }

        a.button {
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;
            color: $primary;
        }
    }
}
</style>