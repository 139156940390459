import { useAuthStore } from "~/stores/auth";

export const fetchCurrentUser = async () => {
    const token = useCookie('auth_token', {
        sameSite: 'lax',
        maxAge: 60 * 60 * 24 * 365,
        expires: new Date(new Date().getDate() + 365)
    });

    const store = useAuthStore();

    if (process.server) {
        if (!token.value) {
            store.token = null;
        } else {
            store.token = token.value;
        }
    }


    if (store.token) {
        try {
            await store.getUser();
        } catch (error) {
            console.log({ error });
            token.value = null;
            store.token = null;
            store.user = null;
        }
    } else {
        token.value = null;
        store.token = null;
        store.user = null;

        try {
            await store.getAddress();
        } catch (error) {

        }
    }
};