import { useCartStore } from "~/stores/cart";

export default defineNuxtPlugin(async (nuxtApp) => {
    if (process.client) {
        const cartStore = useCartStore();

        let couponCookie = useCookie('coupon', { readonly: true });
        // cartStore.promocode = couponCookie.value;

        // console.log({ promo: cartStore.promocode });

        cartStore.fetch(couponCookie.value);
    }
});