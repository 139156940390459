import { useAuthStore } from "~/stores/auth";

export const useApiFetch: typeof useFetch = (request, opts?) => {
    const config = useRuntimeConfig();
    const store = useAuthStore();

    let options = opts ?? {};

    let optionsHeaders: any = {};

    let headers: any = {
        "Accept": "application/json"
    };

    optionsHeaders = {
        ...headers,
        ...(options.headers ?? {})
    };

    if (store.token) {
        optionsHeaders.Authorization = `Bearer ${store.token}`;
    } else if (options.tempGuest && options.tempGuest === true) {
        options.query = { temp_guest_id: store.getTempGuestId(), ...(options.query ?? {}) };
    }

    options.headers = optionsHeaders;


    return useFetch(request, {
        baseURL: config.public.baseURL, ...options
    });
};