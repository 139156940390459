import { useAuthStore } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to, from) => {

    return;


    const { user } = useAuthStore();


    if (to.path.includes('/auth/register')) {
        if (user && user.registered === true) {
            return navigateTo('/');
        }
    }

    if (to.path.includes('/auth/register')) {
        return;
    }

    if (user) {
        if (user.address == null && !to.path.includes('/address') && to.path != '/') {
            return navigateTo('/address');
        }
        return;
    }
});