
export const useFlocktory = () => {
    if (process.server) {
        return {
            updateCart: (cart?: any) => { },
        };
    }

    return {
        updateCart: (cart?: any) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                window.flocktory = window.flocktory || [];
                window.flocktory.push(['updateCart', {
                    cart: {
                        items: (cart?.cart_products ?? []).map((el: any) => {
                            return {
                                id: el.product.id,
                                price: el.total,
                                count: el.quantity
                            };
                        })
                    }
                }]);
            } catch (error) { }
        },

        postCheckout: (order: any, user?: any) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                window.flocktory = window.flocktory || [];
                window.flocktory.push(['postcheckout', {
                    user: {
                        name: user.name,
                        email: user.email ? user.email : `${user.phone}@unknown.email`
                    },
                    order: order,
                    spot: 'desktop',
                }]);
            } catch (error) {

            }
        }
    };
};