<template>
    <NuxtLink :to="formattedPath" v-bind="$attrs">
        <slot />
    </NuxtLink>
</template>

<script setup lang="ts">
const route = useRoute();
const props = defineProps<{ to?: string | object; }>();

const formattedPath = computed(() => {
    let to = props.to;

    if (!to) {
        return to;
    }

    if (typeof to === 'string') {
        if (to.startsWith('http')) {
            return to;
        }
    } else if (typeof to === 'object' && to.path) {
        if (to.path.startsWith('http')) {
            return to;
        }
    }

    const city = route.params.city;

    if (city) {
        if (typeof to === 'string') {
            return `/${city}${to}`;
        } else if (typeof to === 'object' && to.path) {
            return {
                ...to,
                path: `/${city}${to.path}`
            };
        }
    }

    return to;
});
</script>