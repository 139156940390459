
export default defineNuxtRouteMiddleware(async (to, from) => {
    let addressRedirect = false;
    let r = to;
    let rest = null;

    if (to.query['ir']) {

        rest = to.query['ir'];

        if (rest) {
            addressRedirect = true;
        }

        delete r.query['ir'];
    }

    if (addressRedirect) {
        try {
            await useDFetch("user/attach-address/quick", {
                method: "POST",
                tempGuest: true,
                body: {
                    code: rest
                }
            });
        } catch (error) {
            //
        }

        await new Promise(resolve => setTimeout(resolve, 300));

        return navigateTo(r);
    }
});