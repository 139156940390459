<script setup>
import { closeDialog } from 'vue3-promise-dialog';

const props = defineProps(['popup']);
const { $bootstrap } = useNuxtApp();

var modal = null;

onMounted(() => {
    const router = useRouter();
    var modalEl = document.getElementById('cart-popup-modal');
    modal = new $bootstrap.Modal(modalEl, {});
    modalEl.addEventListener('hidden.bs.modal', function (event) {
        closeDialog(true);
    });
    modal.show();
});

onBeforeUnmount(() => {
    modal.hide();
});

const close = () => {
    modal.hide();
};

const getIcon = () => {
    switch (props.popup.icon) {
        case 'rest-closed':
            return '/images/cart-popup-closed.png';
        case 'rest-closed-time':
            return '/images/cart-popup-sleep.png';
        case 'rest-no-delivery':
            return '/images/cart-popup-pickup.png';
        case 'rest-no-pickup':
            return '/images/cart-popup-delivery.png';
        default:
            return null;
    }

    return null;
}

</script>
<template>
    <div class="modal fade cart-popup-modal" id="cart-popup-modal" tabindex="-1"
        aria-labelledby="cart-popup-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 v-if="popup.title" class="cart-popup-title">{{ popup.title }}</h3>
                    <div class="cart-popup-image">
                        <img v-if="getIcon() != null" :src="getIcon()" width="50">
                    </div>
                    <div class="cart-popup-text-1" v-html="popup.text_1"></div>
                    <div class="cart-popup-text-2" v-html="popup.text_2"></div>
                    <div class="cart-popup-text-3" v-html="popup.text_3"></div>

                    <div class="cart-popup-button">
                        <DButton :light="true" @on-tap="close">{{ popup.button_text }}</DButton>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cart-popup-modal {

    .modal-content {
        background-color: #fff;
        border-radius: 20px;
    }

    .modal-body {
        text-align: center;

        .cart-popup-image {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .cart-popup-title {
            color: #000;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 10px;
        }

        .cart-popup-text-1 {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .cart-popup-text-2 {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 20px;
        }

        .cart-popup-text-3 {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .cart-popup-button {
            margin-top: 20px;
        }
    }
}
</style>