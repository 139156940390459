import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45query_45global from "/app/middleware/00.query.global.ts";
import _01_45register_45global from "/app/middleware/01.register.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45query_45global,
  _01_45register_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  address: () => import("/app/middleware/address.ts"),
  auth: () => import("/app/middleware/auth.ts"),
  "check-city": () => import("/app/middleware/checkCity.ts"),
  getapp: () => import("/app/middleware/getapp.ts"),
  guest: () => import("/app/middleware/guest.ts")
}