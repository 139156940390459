import plugin from 'vue-yandex-maps';

const settings = {
    apiKey: 'dbddb7f3-8f18-4c73-9510-3ff3678d8e95',
    lang: 'ru_RU',
    coordorder: 'longlat',
    debug: false,
    version: '2.1'
};

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(plugin, settings);
});