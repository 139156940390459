

export const useInsider = () => {
    if (process.server) {
        return {
            page: (type: string, key: string, data: object) => { },
            user: (user: any) => { }
        };
    }

    return {
        page: (type: string, key: string, data: object) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                window.insider_object = window.insider_object || {};
                window.insider_object = {
                    ...window.insider_object,
                    "page": {
                        "type": type
                    },
                    key: data
                };
            } catch (error) { }
        },

        user: (user: any) => {
            if (!useRuntimeConfig().public.isDomino) {
                return;
            }

            try {
                const dayjs = useDayjs();

                window.insider_object = window.insider_object || {};
                window.insider_object.user = {
                    "uuid": String(user.id),
                    "birthday": dayjs(user.birthday).toISOString(),
                    "gdpr_optin": true,
                    "name": user.name,
                    "surname": user.surname,
                    "username": user.surname + "_" + user.name,
                    "email": user.email,
                    "email_optin": user.email != null,
                    "phone_number": "+" + user.phone,
                    "sms_optin": true,
                    "language": "ru-ru",
                    // "returning": true,
                    "custom": {
                        "restaurant_city": user.address?.city?.name ?? "unknown",
                        "restaurant_code": "unknown"
                    },
                    // "has_transacted": true,
                    // "transaction_count": 2,
                };
            } catch (error) { }
        }
    };
};