
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import NoAddressDialog from '~/components/user/NoAddressDialog.vue';
import { customDialog } from '~/utils/dialogs';

enum AuthState {
    None,
    Code,
    LoggedIn
}

interface IUser {
    name: string;
}

export const useAuthStore = defineStore('auth', {

    state: () => ({
        token: null as string | null,
        user: null as any,
        address: null as any,
        loyalty: null as any,
        authState: AuthState.None,
        registered: null,
        addressSwitcherCourierSelected: true,
        cachedTempGuestId: null as string | null,
    }),
    // persist: {
    //     storage: persistedState.localStorage,
    //     paths: ['token'],
    // },
    getters: {
        addressSwitcherSameSelected(state) {
            if (!state.address) {
                return null;
            }

            return state.address.self_pickup == !state.addressSwitcherCourierSelected;
        },

    },

    actions: {
        setTempGuestId() {
            const tempGuestId = useCookie('temp_guest_id', {
                maxAge: 60 * 60 * 24 * 365,
                expires: new Date(new Date().getDate() + 365),
            });

            if (!tempGuestId.value) {
                this.cachedTempGuestId = uuidv4();
                tempGuestId.value = this.cachedTempGuestId;
            } else {
                this.cachedTempGuestId = tempGuestId.value;
            }
        },

        getTempGuestId() {
            if (!this.cachedTempGuestId) {
                this.setTempGuestId();
            }

            return this.cachedTempGuestId;
        },

        clearTempGuestId() {
            const tempGuestId = useCookie('temp_guest_id', {
                maxAge: 60 * 60 * 24 * 365,
                expires: new Date(new Date().getDate() + 365)
            });

            tempGuestId.value = undefined;
        },

        async validateAddress() {
            if (this.address == null && this.user?.address == null) {
                customDialog(NoAddressDialog);
                return false;
            }

            return true;
        },

        async saveToken(token: string | null) {
            this.token = token;
            await this.getUser();
        },

        async getUser(): Promise<Boolean> {

            if (this.token == null) {
                return this.getAddress();
            }

            try {
                if (useRuntimeConfig().public.isDomino) {
                    const [res, loyaltyRes] = await Promise.all([
                        useDFetch('/auth/user'),
                        useDFetch('/auth/user/loyalty')
                    ]);
                    // const res = await useDFetch('/auth/user');
                    this.user = res;
                    this.address = this.user?.address;

                    if (process.client) {
                        this.clearTempGuestId();
                    }

                    this.addressSwitcherCourierSelected = !(this.address?.self_pickup ?? false);
                    this.loyalty = loyaltyRes;

                    if (this.user) {
                        useInsider().user(this.user);
                    }
                } else {
                    const [res] = await Promise.all([
                        useDFetch('/auth/user')
                    ]);
                    // const res = await useDFetch('/auth/user');
                    this.user = res;
                    this.address = this.user?.address;

                    if (process.client) {
                        this.clearTempGuestId();
                    }

                    this.addressSwitcherCourierSelected = !(this.address?.self_pickup ?? false);
                    this.loyalty = null;
                }

                return true;
            } catch (error) {
                console.log({ userError: error });
                this.token = null;
                // const cookieToken = useCookie('auth_token', {
                //     sameSite: 'strict',
                //     maxAge: 60 * 60 * 24 * 365,
                //     expires: new Date(new Date().getDate() + 365)
                // });
                // cookieToken.value = undefined;

                this.user = null;
                this.address = null;
            }

            return false;
        },

        async getAddress(): Promise<Boolean> {
            try {
                const [res] = await Promise.all([
                    useDFetch('/user/address', {
                        tempGuest: true
                    }),
                ]);

                this.address = res;
                this.addressSwitcherCourierSelected = !(this.address?.self_pickup ?? false);

                if ((process.env.PROJECT || 'domino') == 'a4') {
                    this.addressSwitcherCourierSelected = true;
                }

                return true;
            } catch (error) {
                console.log({ error });
                this.address = null;
            }

            return false;
        },

        async getLoyalty(): Promise<Boolean> {
            const res = await useDFetch('/auth/user/loyalty');

            this.loyalty = res;

            return true;
        },
    },
});