import { openDialog } from 'vue3-promise-dialog';
import InfoDialog from '~/components/ui/modals/InfoDialog.vue';
import CartPopupDialog from '../components/ui/modals/CartPopupDialog.vue';
import ErrorDialog from '../components/ui/modals/ErrorDialog.vue';

export async function errorDialog(text: string) {
    return await openDialog(ErrorDialog, { text });
}

export async function infoDialog(text: string, buttonText: string = "Ок") {
    return await openDialog(InfoDialog, { text, buttonText });
}

export async function customDialog(view: object) {
    return await openDialog(view);
}

export async function cartPopup(popup: object) {
    const dayjs = useDayjs();

    let date = localStorage.getItem(`cartPopup.${popup.key}`);
    let dateObj = null;
    let show = true;

    if (date != null) {
        try {
            let diff = dayjs(date).diff(dayjs(), 'minutes');
            show = Math.abs(diff) >= 15;
        } catch (error) {
            console.log({ error });
        }
    }

    if (show) {
        localStorage.setItem(`cartPopup.${popup.key}`, dayjs().format('YYYY-MM-DD HH:mm:ss'));
        return await openDialog(CartPopupDialog, { popup });
    }

    return null;
}