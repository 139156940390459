import { useAuthStore } from "~/stores/auth";

export const useDFetch = (request: string, opts?: any) => {
    const config = useRuntimeConfig();
    const store = useAuthStore();

    let options = opts ?? {};

    let optionsHeaders: any = {};

    let headers: any = {
        "Accept": "application/json",
        "Cache-Control": "no-store, no-cache"
    };

    optionsHeaders = {
        ...headers,
        ...(options.headers ?? {})
    };

    if (store.token) {
        optionsHeaders.Authorization = `Bearer ${store.token}`;
    } else if (options.tempGuest && options.tempGuest === true) {
        options.query = { temp_guest_id: store.getTempGuestId(), ...(options.query ?? {}) };
    }

    options.headers = optionsHeaders;

    return $fetch.create({
        baseURL: config.public.baseURL,
        ...options
    })(request);
};